<template lang="html">
  <div>
    <template>
      <div id="data-list-list-view" class="data-list-container">
        <vs-table  search max-items="5" pagination :data="trashedUsers">
            <template slot="thead">
              <vs-th sort-key="id">{{$t('ID')}}</vs-th>
              <vs-th sort-key="name">{{$t('Identity')}}</vs-th>
              <vs-th sort-key="email">{{$t('Contact')}}</vs-th>
              <vs-th sort-key="user_name">{{$t('UserName')}}</vs-th>
              <vs-th sort-key="balance">{{$t('Balance')}}</vs-th>
              <vs-th sort-key="status">{{$t('status')}}</vs-th>
              <vs-th sort-key="created_at">{{$t('Created')}} / {{$t('Updated')}} / {{$t('Deleted')}}</vs-th>
              <vs-th>{{$t('Role')}}</vs-th>
              <vs-th>{{$t('Options')}}</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.id">
                  <vs-chip color="primary">
                    {{ tr.id }}
                  </vs-chip>
                </vs-td>
                <vs-td :data="tr.name">
                  <div style="width: max-content">
                          <span style="color: rgba(var(--vs-primary), 1);" class="ml-2"><font-awesome-icon
                            :icon="['fas', 'address-card']"/></span><span class="ml-2">{{ tr.name }}</span>
                  </div>
                  <div style="width: max-content">
                      <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                      <font-awesome-icon color="primary" :icon="['fas', 'passport']"/>
                      </span>
                    {{ tr.tc }}
                  </div>
                </vs-td>
                <vs-td :data="tr.email">
                  <div @click="SendEmail(tr.email)" style="width: max-content">
                    <vx-tooltip color="primary" :text="$t('Click to send email')">
                      <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                        <font-awesome-icon :icon="['fas', 'envelope']"/>
                      </span>
                      <span class="ml-2">{{ tr.email }}</span>
                    </vx-tooltip>
                  </div>
                  <div @click="MakeCall(tr.mobile)" style="width: max-content">
                    <vx-tooltip color="primary" :text="$t('Click to call')">
                        <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                        <font-awesome-icon :icon="['fas', 'phone']"/>
                        </span>
                      <span class="ml-2">{{ tr.mobile }}</span>
                    </vx-tooltip>
                  </div>
                </vs-td>
                <vs-td :data="tr.user_name">
                  <div style="width: max-content">
                          <span style="color: rgba(var(--vs-primary), 1);" class="ml-2"><font-awesome-icon
                            :icon="['fas', 'user']"/></span>
                    <span class="ml-2">{{ tr.user_name }}</span>
                  </div>
                </vs-td>
                <vs-td :data="tr.balance">
                  <div style="width: max-content">
                    <vx-tooltip color="primary" :text="$t('Income:')+ tr.balance + $t('Outcome:')+tr.balance">
                         <span style="color: rgba(var(--vs-primary), 1);" class="ml-2">
                           <font-awesome-icon :icon="['fas', 'dollar-sign']"/>
                         </span>
                      <span class="ml-2">{{ tr.balance }}</span>

                    </vx-tooltip>
                  </div>
                </vs-td>
                <vs-td :data="tr.status">
                  <div style="width: max-content">
                    <vs-chip transparent
                             :color="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'">
                      <vs-avatar
                        :color="tr.status==='Active'?'rgb(var(--vs-success))':tr.status==='Blocked'?'rgb(var(--vs-danger))':'rgb(var(--vs-warning))'"
                        :icon="tr.status==='Active'?'check_circle_outline':tr.status==='Blocked'?'block':'unsubscribe'"
                      />
                      <vx-tooltip :color="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'"
                                  :text="tr.status==='Active'?$t('This User is active'):tr.blocked_reason"
                                  position="top">
                        {{ tr.status }}
                      </vx-tooltip>
                    </vs-chip>
                  </div>
                </vs-td>
                <vs-td :data="tr.CreatedAt">
                  <div style="width: max-content">
                    <vs-chip transparent color="success">
                      <vs-avatar
                        color="rgb(var(--vs-success))"
                        icon="event_available"
                      />
                      <vx-tooltip color="success" :text="tr.created_at">
                        {{ tr.created_from }}
                      </vx-tooltip>
                    </vs-chip>
                  </div>
                  <div style="width: max-content">
                    <vs-chip transparent color="warning">
                      <vs-avatar color="rgb(var(--vs-warning))" icon="update"/>
                      <vx-tooltip
                        transparent
                        color="warning"
                        :text="tr.updated_at">
                        {{ tr.updated_from }}
                      </vx-tooltip>
                    </vs-chip>
                  </div>
                  <div style="width: max-content">
                    <vs-chip transparent color="danger">
                      <vs-avatar color="rgb(var(--vs-danger))" icon="delete"/>
                      <vx-tooltip
                        transparent
                        color="danger"
                        :text="tr.deleted_at">
                        {{ tr.deleted_from }}
                      </vx-tooltip>
                    </vs-chip>
                  </div>
                </vs-td>
                <vs-td :data="tr.rolecount">
                  <div style="width: max-content">
                    <vx-tooltip color="primary" text="Click to see the Roles" position="top">
                            <span style="color: rgba(var(--vs-primary), 1);" class="ml-2"> <font-awesome-icon
                              :icon="['fas', 'user-shield']"/></span>
                      {{ tr.rolecount }}
                    </vx-tooltip>
                  </div>
                </vs-td>
                <vs-td :data="tr.id">
                  <vx-tooltip color="success" :text="$t('Restore')" position="top">
                  <a class="ml-1 mr-1" @click="RestoreUserAlert(tr.id)" >
                    <font-awesome-icon
                      color="rgb(var(--vs-success))"
                      :icon="['fas', 'trash-restore']"
                    />
                  </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
        </vs-table>
      </div>
    </template>
    <div>
      <vx-card title="Explanation" class="mt-10">
        <vs-row>
          <vs-col class="mb-3" vs-w="3" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-chip transparent color="success">
              <vs-avatar
                color="rgb(var(--vs-success))"
                icon="event_available"
              />
              {{$t('Created At')}}
            </vs-chip>
          </vs-col>
          <vs-col class="mb-3" vs-w="3" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-chip transparent color="warning">
              <vs-avatar
                color="rgb(var(--vs-warning))"
                icon="update"
              />
              {{$t('Updated At')}}
            </vs-chip>
          </vs-col>
          <vs-col class="mb-3" vs-w="3" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-chip transparent color="danger">
              <vs-avatar color="rgb(var(--vs-danger))" icon="delete"/>
                {{$t('Deleted At')}}
            </vs-chip>

          </vs-col>
          <vs-col class="mb-3" vs-w="3" vs-sm="6" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-chip transparent>
              <font-awesome-icon
                class="mr-2"
                color="rgb(var(--vs-success))"
                :icon="['fas', 'trash-restore']"
              />
            </vs-chip>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: () => ({
    restoredUser:'',
  }),
  watch: {},
  computed: {
    trashedUsers() {
      return this.$store.getters["user/trashedUsers"]
    },
  },
  methods: {
    RestoreUserAlert($val) {
      this.restoredUser = $val
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('Confirm Restore'),
        text: this.$t('Are you sure you want to restore this User'),
        accept: this.acceptAlert,
        acceptText: this.$t('Restore'),
        cancelText: this.$t('Cancel')
      })
    },
    acceptAlert() {
      this.$store.dispatch('user/RestoreUser',this.restoredUser)
    },
    MakeCall(tel) {
      window.location.href = `tel://${tel}`;
    },
    SendEmail(email) {
      window.location.href = `mailto://${email}`;
    },
  },

  created() {
    this.$store.dispatch('user/GetTrashedData',this.restoredUser)
  }

}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}

</style>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}


.vs-sidebar {
  z-index: 400000;
}
</style>
